export const linksProfessions = [
  {
    name: 'strażacy',
    href: 'https://www.youtube.com/watch?v=mpLbjWu1Dag',
  },
  {
    name: 'zakonnice',
    href: 'https://youtu.be/JazDPJPC2TE?t=43',
  },
  {
    name: 'księża',
    href: 'https://www.youtube.com/watch?v=MZl776BY2ms',
  },
  {
    name: 'zakonnicy',
    href: 'https://www.youtube.com/watch?v=Xk2A1Dt9nMU',
  },
  {
    name: 'nauczyciele',
    href: 'https://www.youtube.com/watch?v=q-BfA4eTJW0&t=102s',
  },
  {
    name: 'muzycy folkowi',
    href: 'https://www.youtube.com/watch?v=yvM9dZiDWM4',
  },
  {
    name: 'dentyści',
    href: 'https://www.youtube.com/watch?v=HCxH0Rpi9hY',
  },
  {
    name: 'policjanci',
    href: 'https://www.youtube.com/watch?v=EygdB3PG4C0',
  },
  {
    name: 'strażnicy',
    href: 'https://www.youtube.com/watch?v=PRe8cJYaShM',
  },
  {
    name: 'lektorzy',
    href: 'https://www.youtube.com/watch?v=-ZiH3nrn7aQ',
  },
  {
    name: 'modelki',
    href: 'https://www.youtube.com/watch?v=gB5LXX2JyE4',
  },
]

export const linksPoliticians = [
  {
    name: 'Ryszard Kalisz',
    href: 'https://www.youtube.com/watch?v=Ipy4DyEwaZg',
  },
  {
    name: 'Janusz Korwin-Mikke',
    href: 'https://www.youtube.com/watch?v=m0XDcTOxQM0',
  },
]
export const links = [
  {
    name: 'prof. Jerzy Bralczyk',
    href: 'https://www.youtube.com/watch?v=qZAm5ALLj_Y',
  },
  {
    name: 'Zbigniew Boniek',
    href: 'https://www.youtube.com/watch?v=s-3Qzp5NTj8',
  },
  {
    name: 'Bracia Mroczek',
    href: 'https://www.youtube.com/watch?v=QDp_0FUuSk0',
  },
  // {
  //   name: 'dział marketingu M&Ms',
  //   href: 'https://www.youtube.com/watch?v=pBxhaPFWcnU',
  // },

  // {
  //   name: 'KAYAH',
  //   href: 'https://www.youtube.com/watch?v=UyByWSb-Cgk',
  // },
  {
    name: 'Beata Kozidrak',
    href: 'https://www.youtube.com/watch?v=uk9VlbOhm1g',
  },
  {
    name: 'Krystyna Czubówna',
    href: 'https://www.youtube.com/watch?v=uk9VlbOhm1g',
  },
  // {
  //   name: '',
  //   href: '',
  // },
  // {
  //   name: '',
  //   href: '',
  // },
  // {
  //   name: '',
  //   href: '',
  // },
]
