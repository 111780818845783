import React from 'react'
import { Box, Button, Text, Flex, Heading } from 'rebass/styled-components'

import * as d3 from 'd3'
import { Link } from 'rebass'
import { H1, Overlay, Slide } from './Slide'
import {
  isDesktop,
  resetCircles,
  transformScale,
  zoomControlCallback,
} from './helpers'
import { links, linksPoliticians, linksProfessions } from './links'

const slides = [
  {
    depthToShow: 100,
    slideCallback: (w, h) => {
      zoomControlCallback(1.5, 500)(w, h)
    },
    component: <Slide></Slide>,
  },
  {
    slideCallback: (w, h) => {
      zoomControlCallback(1)(w, h)
      resetCircles()
    },
    depthToShow: 100,
    component: <Slide></Slide>,
  },
  {
    slideCallback: (w, h) => {
      resetCircles()
      zoomControlCallback(1, 1)(w, h)
    },
    component: (
      <Slide>
        <Overlay>
          <H1 fontSize={[2, 6]}>
            Akcja hot16challenge2 zaczęła się 28 kwietnia, dwa tygodnie po
            zamknięciu Polskich granic w związku z koronawirusem
          </H1>
          <Button
            as={'a'}
            href={'/hot16challenge2-en'}
            style={{
              position: 'fixed',
              bottom: 20,
              right: 20,
              width: 'fit-content',
            }}
          >
            English
          </Button>
        </Overlay>
      </Slide>
    ),
  },
  {
    slideCallback: (w, h) => {
      zoomControlCallback(1)(w, h)
      resetCircles()
    },
    component: (
      <Slide>
        <Overlay>
          <H1 fontSize={[2, 6]}>
            Polega na zbiórce pieniędzy mającej na celu wsparcie służby zdrowia
            w walce z pandemią
          </H1>
        </Overlay>
      </Slide>
    ),
  },
  // {
  //   depthToShow: 100,
  //   component: <Slide></Slide>,
  // },
  {
    // depthToShow: 1,
    slideCallback: (w, h) => {
      zoomControlCallback(1)(w, h)
      d3.selectAll('circle').transition().duration(750).attr('r', 1)
    },
    component: (
      <Slide>
        <p>
          Nawiązuje do akcji #hot16challenge sprzed 6 lat, która była rapową
          odpowiedzią na popularne #iceBucketChallenge
        </p>
      </Slide>
    ),
  },
  {
    slideCallback: (width, height) => {
      // d3.selectAll('path').transition().duration(250).attr('stroke-width', 0)
      // d3.select('#leaf-1')
      //   .transition()
      //   .duration(750)
      //   .attr('r', isDesktop(width) ? 20 : 10)
      zoomControlCallback(isDesktop(width) ? 10 : 8)(width - 200, height - 200)
    },
    clicked: 'Solar',
    component: (
      <Slide>
        <Overlay>
          <H1 fontSize={[2, 6]}>Wszystko zaczyna sie od Solara...</H1>
        </Overlay>
      </Slide>
    ),
  },
  {
    depthToShow: 1,
    clicked: 'Solar',
    filter: (e) => true,
    slideCallback: (width, height) => {
      zoomControlCallback(isDesktop(width) ? 8 : 4)(width - 200, height - 200)
    },
    // transform: transformScale(20),
    component: (
      <Slide>
        <p>
          28 kwietnia nagrywa 16 wersów i wyzywa do wsparcia zbiórki na portalu
          siepomaga.pl.
        </p>
        Nominuje Bedoesa, Taco Hemingwaya, Białasa, Matę oraz Mike Posnera do
        nagrania odpowiedzi w ciągu 72 godzin.
      </Slide>
    ),
  },
  // {
  //   depthToShow: 1,
  //   clicked: 'Solar',
  //   filter: (e) => true,
  //   component: (
  //     <Slide>
  //       <p>
  //         28 kwietnia nagrywa 16 wersów i wyzywa do wsparcia zbiórki na portalu
  //         siepomaga.pl.
  //       </p>
  //       Nominuje Bedoesa, Taco Hemingwaya, Białasa, Matę oraz Mike Posnera do
  //       nagrania odpowiedzi w ciągu 72 godzin.
  //     </Slide>
  //   ),
  // },
  {
    depthToShow: 2,
    slideCallback: (w, h) => {
      zoomControlCallback(isDesktop(w) ? 5 : 4)(
        isDesktop(w) ? w - 630 : w - 230,
        h
      )
    },
    component: (
      <Slide>1 maja odpowiadają Taco i Mata, 2 maja - Bedoes i Białas</Slide>
    ),
  },
  {
    depthToShow: 3,
    // slideCallback: (w, h) => {
    //   zoomControlCallback(isDesktop(w) ? 5 : 4)(
    //     isDesktop(w) ? w - 630 : w - 230,
    //     h
    //   )
    // },
    clicked: 'Taco Hemingway',
    component: (
      <Slide>Nominują kolejnych artystów do wzięcia udziału w akcji</Slide>
    ),
  },
  {
    depthToShow: 3,
    clicked: 'Taconafide',
    slideCallback: (w, h) => {
      zoomControlCallback(3)(isDesktop(w) ? w : w - 150, h)
    },
    component: <Slide>Taco nominuje Taconafide</Slide>,
  },
  {
    depthToShow: 4,
    slideCallback: (w, h) => {
      zoomControlCallback(2.5)(isDesktop(w) ? w : w - 120, h)
    },
    clicked: 'Dawid Podsiadło',
    clickedZoom: 3,
    component: <Slide>Taconafide nominuje Dawida Podsiadłę</Slide>,
  },
  {
    depthToShow: 5,
    slideCallback: (w, h) => {
      zoomControlCallback(2)(isDesktop(w) ? w : w - 120, h)
    },
    clicked: 'Monika Brodka',
    component: (
      <Slide>
        Który nominując Monikę Brodkę przedstawia akcję szerszej publice
      </Slide>
    ),
  },
  {
    depthToShow: 8,
    // filter: (e) => false,
    // transform: transformScale(10),
    slideCallback: (w, h) => {
      // d3.selectAll('circle').transition().duration(750).attr('fill', '#555')
      zoomControlCallback(isDesktop(w) ? 2 : 2.5)(
        isDesktop(w) ? w - 200 : w - 50,
        isDesktop(h) ? h - 200 : h - 200
      )
    },
    clicked: 'Majka Jeżowska',
    component: (
      <Slide>
        Do akcji włączają się artyści znani raczej z innych gatunków, jak
        Katarzyna Nosowska, Kayah, czy Majka Jeżowska
      </Slide>
    ),
  },
  {
    depthToShow: 9,
    slideCallback: (width, height) => {
      zoomControlCallback(1.8)(width, height)
      d3.selectAll('#zoom text').remove()
      d3.selectAll('circle')
        .transition()
        .duration(750)
        .attr('r', isDesktop(width) ? 5 : 1)
    },
    // clicked: 'Monika Brodka',
    // clickedZoom: 3,
    component: (
      <Slide>
        <Overlay>
          <H1 fontSize={[2, 6]}>Akcja wyszła mocno poza świat hiphopu.</H1>
          <br />
          <H1 fontSize={[2, 6]}>
            Włączyły się do niej osoby z różnych środowisk, niezwiązane z
            muzyką, jak, Tata Maty, znany szerzej jako prof. Matczak, Michał
            Karmowski czy Zbigniew Stonoga.
          </H1>
        </Overlay>
      </Slide>
    ),
  },
  // {
  //   depthToShow: 10,
  //   // filter: (e) => false,
  //   // transform: transformScale(10),
  //   slideCallback: (w, h) => {
  //     resetCircles()
  //     // zoomControlCallback(1)(w, h)
  //     d3.selectAll('#zoom text').remove()
  //   },
  //   component: (
  //     <Slide>Nagle hiphopowe kawałki zaczynają nagrywać politycy...</Slide>
  //   ),
  // },
  {
    depthToShow: 12,
    slideCallback: (w, h) => {
      return zoomControlCallback(isDesktop(w) ? 1.3 : 2)(
        w - 200,
        h - (isDesktop(w) ? 100 : 0)
      )
    },
    clicked: 'Adam Bodnar',
    component: (
      <Slide>
        Nagle hiphopowe kawałki zaczynają nagrywać politycy, <br />
        oraz inne osoby publiczne, jak
        <i style={{ color: 'red' }}>Rzecznik Praw Obywatelskich</i>
      </Slide>
    ),
  },
  // {
  //   depthToShow: 16,
  //   slideCallback: (w, h) => {
  //     resetCircles()
  //     zoomControlCallback(1)(w, h)
  //     // TODO mark politicians
  //     d3.selectAll('#zoom text').remove()
  //   },
  //   component: <Slide></Slide>,
  // },
  {
    depthToShow: 100,
    // clicked: 'Andrzej Duda',
    slideCallback: resetCircles,
    component: (
      <Slide>
        <Overlay>
          <H1 fontSize={[2, 6]}>
            Po niecałych 2 tygodniach od rozpoczęcia akcji, nominowany przez
            Zeusa,{' '}
          </H1>
          <H1 fontSize={[2, 6]}>
            <i style={{ color: 'red' }}>"ostrym cieniem mgły"</i>
          </H1>
          <H1 fontSize={[2, 6]}>zaskoczył wszystkich prezydent Polski</H1>
        </Overlay>
      </Slide>
    ),
  },
  {
    depthToShow: 100,
    clicked: 'Andrzej Duda',
    slideCallback: (w, h) => {
      zoomControlCallback(2)(
        isDesktop(w) ? w + 700 : w + 200,
        isDesktop(w) ? h + 700 : h + 200
      )
    },
    component: <Slide></Slide>,
  },
  {
    depthToShow: 100,
    slideCallback: (w, h) => {
      zoomControlCallback(1)(w, h)
      d3.selectAll('#zoom text').remove()
      resetCircles()
    },
    noLogo: true,
    component: (
      <Slide>
        <Overlay>
          <h1>Do akcji dołączyły tysiące osób.</h1>
          <Box style={{ maxWidth: '100%', width: 768 }}>
            <Text fontSize={[1, 2]}>
              Przedstawiciele różnych grup zawodowych:
              <br />
              <span style={{ width: '100%', overflowWrap: 'break-word' }}>
                {linksProfessions.map((link, i) => (
                  <span>
                    <Link href={link.href} target={'_blank'}>
                      {link.name}
                    </Link>
                    {i == linksProfessions.length - 1 ? '.' : ', '}
                  </span>
                ))}
              </span>
            </Text>
            <Text fontSize={[1, 2]} mt={2}>
              {'Politycy '}
              <Link href={linksPoliticians[0].href} target={'_blank'}>
                lewicowi
              </Link>{' '}
              i
              <span>
                {' '}
                <Link href={linksPoliticians[1].href} target={'_blank'}>
                  prawicowi
                </Link>
                .
              </span>
            </Text>
            <Text fontSize={[1, 2]} mt={2} mb={2}>
              {
                'I wiele innych osób z rapem raczej nie kojarzonych, takich jak '
              }
              {links.map((link, i) => (
                <span>
                  <Link href={link.href} target={'_blank'}>
                    {link.name}
                  </Link>
                  {i == links.length - 1 ? '.' : ', '}
                </span>
              ))}
            </Text>
            <br />
            Oraz tysiące innych.
          </Box>
        </Overlay>
      </Slide>
    ),
  },
  {
    component: (
      <Slide>
        <Overlay>
          <Text>Wielu osobom akcja szybko zbrzydła.</Text>
          <Text>
            Krytykowano teksty, niedostateczne wskazywanie na cel - wsparcie
            służb medycznych w bardzo trudnym okresie, próby pokazania się
            polityków i zabiegi marketingowe firm.{' '}
          </Text>
        </Overlay>
      </Slide>
    ),
  },
  {
    noLogo: true,
    component: (
      <Slide>
        <Overlay smallerMobileText>
          <h1>Zebrano ponad 3,5 mln zł</h1>
          <Text> </Text>
          <Text>Czy to dużo?</Text>
          <Text> </Text>
          <Text>Na pewno za mało żeby uzdrowić służbę zdrowia.</Text>
          <Text>To mniej niż 1 grosz za każde wyświetlenie.</Text>
          <Text>
            Z drugiej strony to o wiele więcej niż pierwotny
            cel&nbsp;-&nbsp;1&nbsp;mln&nbsp;zł.
          </Text>
          <Text>
            Dla porównania - Wielka Orkiestra Świątecznej Pomocy zebrała w tym
            roku 186 mln złotych.{' '}
          </Text>
          <Text>W tym kontekście to nie najgorszy wynik.</Text>
        </Overlay>
      </Slide>
    ),
  },
  {
    component: (
      <Slide>
        <Overlay>
          <Text>
            Pomijając aspekt finansowy, akcja dostarczyła wiele radości w czasie
            niepewności, gdy (prawie) wszyscy siedzieli w domach, i
            przypomniała, że rapować może każdy, choć z różnym skutkiem.
          </Text>
          <Text>Za to wszystko należą się Solarowi słowa uznania 👏</Text>
        </Overlay>
      </Slide>
    ),
  },
  {
    depthToShow: 100,
    component: (
      <Slide>
        <Overlay centered>
          <Link href={'https://www.siepomaga.pl/hot16challenge'}>
            link do zbiórki
          </Link>
          <Link href={'https://hot16challenge.network/'} m={4}>
            oryginalna strona akcji
          </Link>
          <Flex alignItems={'center'} justifyContent={'center'} mb={2}>
            <h2 style={{ margin: 0 }}>Udostępnij </h2>
            <iframe
              src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.sawka.pro%2Fhot16challenge2&layout=button_count&size=large&appId=865485733593064&width=88&height=28"
              width="130"
              style={{ marginLeft: 15, marginTop: 5, marginRight: 10 }}
              height="40"
              scrolling="no"
              frameBorder="0"
              allowTransparency="true"
              allow="encrypted-media"
            ></iframe>
          </Flex>

          <Button
            as={'a'}
            href={'/hot16challenge2-chart'}
            style={{ textDecoration: 'none', textAlign: 'center' }}
          >
            Przeglądaj interaktywny wykres ze szczegółami
          </Button>
          <Button
            variant={'outline'}
            style={{ marginTop: 20 }}
            as={'a'}
            href={'/hot16challenge2'}
          >
            Zacznij od nowa
          </Button>
        </Overlay>
      </Slide>
    ),
  },
  {
    component: <div></div>,
  },
]

export const getSlide = (stepIndex = 0) => {
  return {
    transform: transformScale(1),
    filter: (e) => e.data.extra && e.data.extra.responseUrl,
    depthToShow: 100,
    slideCallback: (width, height) => {
      //TODO
    },
    ...slides[stepIndex],
  }
}

export default slides
